import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ModalWarningMessage = _resolveComponent("ModalWarningMessage")!
  const _component_ModalConfirmMessage = _resolveComponent("ModalConfirmMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView),
    _createVNode(_component_ModalWarningMessage, { ref: "modalWarningMessage" }, null, 512),
    _createVNode(_component_ModalConfirmMessage, { ref: "modalConfirmMessage" }, null, 512)
  ], 64))
}