export class JWTRefreshError extends Error {
  constructor(message: string) {
    super(message);
    this.name = `JWTRefreshError`;
    Object.setPrototypeOf(this, JWTRefreshError.prototype);
  }
}

export class APIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = `APIError`;
    Object.setPrototypeOf(this, APIError.prototype);
  }
}

export default {
    APIError,
    JWTRefreshError,
}