<template>
  <div :class="useVertical ? `` : `btn-toolbar`" v-bind="$attrs">
    <template v-if="positionBottom">
      <div :class="['d-flex', 'flex-column', index == 0 ? '' : 'ms-2']" v-for="(option, index) in options" :key="index">
        <label :for="`${componentUUID}-${index}`" :class="[`form-check-label`, ...labelClass]" v-html="option.value"></label>
        <div class="text-center mt-1">
          <input
            :id="`${componentUUID}-${index}`"
            :class="[`form-check-input`, ...inputClass]"
            type="checkbox"
            :disabled="disabled"
            :value="option"
            v-model="selectModel"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="form-check" v-for="(option, index) in options" :key="index">
        <input
          :id="`${componentUUID}-${index}`"
          :class="[`form-check-input`, ...inputClass]"
          type="checkbox"
          :disabled="disabled"
          :value="option"
          v-model="selectModel"
        />
        <label :for="`${componentUUID}-${index}`" :class="[`form-check-label`, `me-2`, ...labelClass]" v-html="option.value"></label>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import KeyValue from '@/types/KeyValue';

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<KeyValue[]>, required: true },
    options: { type: Array as PropType<KeyValue[]>, required: true },
    useVertical: { type: Boolean, default: false },
    positionBottom: { type: Boolean, default: false },
    labelClass: { type: Array as PropType<string[]>, default: () => [] },
    inputClass: { type: Array as PropType<string[]>, default: () => [] },
  },
  data() {
    return {
      componentUUID: this.newUUIDv4(),
      disabled: false,
    };
  },
  mounted() {
    if (this.$attrs.disabled !== undefined) {
      this.disabled = true;
    }
  },
  computed: {
    selectModel: {
      get(): KeyValue {
        return this.modelValue;
      },
      set(value: KeyValue) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
</script>
