import { Component } from 'vue';
import moment from 'moment';

export {};

declare global {
  interface Window {
    callbackVue: Component | null;
  }

  interface Date {
    toStringFormat(format: string): string;
    getWeekNumber(): number;
    getDayFullName(): string;
    getDayShortName(): string;
  }
}

Date.prototype.toStringFormat = function (format = 'YYYY-MM-DD HH:mm:ss'): string {
  return moment(this).format(format);
};

Date.prototype.getWeekNumber = function (): number {
  const date = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  const dayNum = date.getUTCDay() || 7;
  date.setUTCDate(date.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  return Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};

Date.prototype.getDayFullName = function (): string {
  const day = this.getDay();
  const names = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //const names = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  return names[day];
};

Date.prototype.getDayShortName = function (): string {
  const day = this.getDay();
  const names = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //const names = ['일', '월', '화', '수', '목', '금', '토'];
  return names[day];
};
