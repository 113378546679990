<template>
  <template v-if="$attrs.disabled">
    <input class="form-control" :value="dateModel" :placeholder="$attrs.placeholder as string ?? `Select date`" disabled />
  </template>
  <template v-else>
    <FlatPickr class="form-control" v-model="dateModel" :config="pickerConfig" :placeholder="$attrs.placeholder ?? `Select date`" />
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FlatPickr from 'vue-flatpickr-component';

export default defineComponent({
  props: {
    modelValue: { type: String, required: true },
    config: { type: Object, default: () => ({}) },
  },
  components: {
    FlatPickr,
  },
  data() {
    return {
      pickerConfig: {
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d',
        mode: 'single',
        ...this.config,
      },
    };
  },
  computed: {
    dateModel: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
</script>
