import { createApp, Plugin } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@splidejs/vue-splide/css';
import 'flatpickr/dist/flatpickr.css';
import '@/assets/scss/common.scss';
import '@/types/Global';
import Elements from '@/components/elements';
import Helper from '@/helpers/Helper';
import Errors from '@/types/Errors';
import VueGtag from 'vue-gtag';

const elements: Plugin = {
  // eslint-disable-next-line
  install: (app: any, options: any) => {
    for (const key in Elements) {
      app.component(`${key}`, Elements[key as keyof typeof Elements]);
    }
  },
};

const helpers: Plugin = {
  // eslint-disable-next-line
  install: (app: any, options: any) => {
    app.mixin({ methods: { ...Helper } });
  },
};

const errors: Plugin = {
  // eslint-disable-next-line
  install: (app: any, options: any) => {
    app.config.globalProperties.$Errors = Errors;
  },
};

const app = createApp(App)
  .use(router)
  .use(store)
  .use(elements)
  .use(helpers)
  .use(errors);

if (process.env.VUE_APP_GTAG_ID) {
  app.use(
    VueGtag,
    {
      config: {
        id: `${process.env.VUE_APP_GTAG_ID}`,
        params: {
          send_page_view: false,
        },
      },
    },
    router
  );
}

app.mount('#app');

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap';
