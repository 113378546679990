import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["for", "innerHTML"]
const _hoisted_2 = { class: "text-center mt-1" }
const _hoisted_3 = ["id", "disabled", "value"]
const _hoisted_4 = ["id", "disabled", "value"]
const _hoisted_5 = ["for", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: _ctx.useVertical ? `` : `btn-toolbar`
  }, _ctx.$attrs), [
    (_ctx.positionBottom)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['d-flex', 'flex-column', index == 0 ? '' : 'ms-2']),
            key: index
          }, [
            _createElementVNode("label", {
              for: `${_ctx.componentUUID}-${index}`,
              class: _normalizeClass([`form-check-label`, ..._ctx.labelClass]),
              innerHTML: option.value
            }, null, 10, _hoisted_1),
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("input", {
                id: `${_ctx.componentUUID}-${index}`,
                class: _normalizeClass([`form-check-input`, ..._ctx.inputClass]),
                type: "checkbox",
                disabled: _ctx.disabled,
                value: option,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectModel) = $event))
              }, null, 10, _hoisted_3), [
                [_vModelCheckbox, _ctx.selectModel]
              ])
            ])
          ], 2))
        }), 128))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-check",
            key: index
          }, [
            _withDirectives(_createElementVNode("input", {
              id: `${_ctx.componentUUID}-${index}`,
              class: _normalizeClass([`form-check-input`, ..._ctx.inputClass]),
              type: "checkbox",
              disabled: _ctx.disabled,
              value: option,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectModel) = $event))
            }, null, 10, _hoisted_4), [
              [_vModelCheckbox, _ctx.selectModel]
            ]),
            _createElementVNode("label", {
              for: `${_ctx.componentUUID}-${index}`,
              class: _normalizeClass([`form-check-label`, `me-2`, ..._ctx.labelClass]),
              innerHTML: option.value
            }, null, 10, _hoisted_5)
          ]))
        }), 128))
  ], 16))
}