import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-402b4efe"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "position-relative" }, _ctx.$attrs), [
    (_ctx.$attrs.disabled === undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseEnterHandler && _ctx.mouseEnterHandler(...args))),
          onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseLeaveHandler && _ctx.mouseLeaveHandler(...args))),
          ref: "dropdown"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
            'position-relative',
            'form-control',
            'border-avn-input-bg',
            'bg-avn-input-bg',
            'dropdown-text',
            'dropdown-hover',
            _ctx.hideDropDown ? '' : 'rounded-bottom-0',
          ]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropDown && _ctx.toggleDropDown(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.dropDownTitleModel) + " ", 1),
            _createElementVNode("i", {
              class: _normalizeClass(['position-absolute', 'top-50', 'end-0', 'translate-middle-y', 'bi', _ctx.hideDropDown ? 'bi-caret-down-fill' : 'bi-caret-up-fill', 'pe-2'])
            }, null, 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({
            'position-absolute': true,
            'w-100': true,
            'd-none': _ctx.hideDropDown,
          }),
            style: {
            'z-index': 10,
          }
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass({
              'form-control': true,
              'rounded-0': true,
              'rounded-bottom-1': index == _ctx.options.length - 1,
              'bg-avn-input-bg': true,
              'dropdown-text': true,
              'dropdown-hover': true,
              'dropdown-selected': _ctx.isSelected(option),
              'text-truncate': true,
            }),
                onClick: ($event: any) => (_ctx.toggleSelect(option))
              }, _toDisplayString(option.value), 11, _hoisted_1))
            }), 128))
          ], 2)
        ], 544))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          class: _normalizeClass(['form-control']),
          disabled: true,
          value: _ctx.makeTitle(this.selectModel),
          placeholder: _ctx.$attrs.placeholder as string ?? ``
        }, null, 8, _hoisted_2))
  ], 16))
}