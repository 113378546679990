<template>
  <div class="container" v-bind="$attrs">
    <div class="row justify-content-center">
      <div v-if="!hasMessage" class="col-auto">
        <div class="spinner-border text-avn-pink" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="col">
        <h1 class="text-center">{{ titleString }}</h1>
        <p class="text-center">{{ messageString }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titleValue: '',
      messageValue: '',
    };
  },
  methods: {
    setTitleMessage(title: string, message: string) {
      this.titleString = title;
      this.messageString = message;
    },
  },
  computed: {
    hasMessage(): boolean {
      return this.titleString.length > 0 || this.messageString.length > 0;
    },
    titleString: {
      get(): string {
        return this.titleValue.length ? this.titleValue : this.title;
      },
      set(value: string) {
        this.titleValue = value;
      },
    },
    messageString: {
      get(): string {
        return this.messageValue.length ? this.messageValue : this.message;
      },
      set(value: string) {
        this.messageValue = value;
      },
    },
  },
});
</script>
