<template>
  <div class="modal fade" tabindex="-1" aria-labelledby="warningMessage" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="d-flex justify-content-center py-2">
          <span class="modal-title fs-4 fw-bold" v-html="title"></span>
        </div>
        <div class="modal-body text-center border-0">
          <p class="p-5" v-html="message" />
        </div>
        <div class="d-flex justify-content-evenly pt-2 pb-4">
          <button type="button" class="btn btn-avn-gray btn-sm rounded-pill p-3 w-25" @click="cancel" v-html="cancelButton" />
          <button type="button" class="btn btn-avn-white btn-sm rounded-pill p-3 w-25" @click="confirm" v-html="confirmButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-content {
  z-index: 10000000 !important;
  background-color: #373737;
  color: #fff;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

export default defineComponent({
  data() {
    return {
      title: 'AVNANCE alert',
      message: 'MESSAGE',
      cancelButton: 'CANCEL',
      confirmButton: 'CONFIRM',
      modal: null as Modal | null,
      callback: Function as () => void,
    };
  },
  mounted() {
    const modalElement = this.$refs.modal as HTMLElement;
    if (modalElement) {
      this.modal = new Modal(modalElement, { backdrop: 'static' });
    }
  },
  unmounted() {
    this.modal?.hide();
  },
  methods: {
    cancel() {
      this.modal?.hide();
      this.callback(false);
    },
    confirm() {
      this.modal?.hide();
      this.callback(true);
    },
    show({
      title,
      message,
      cancelButton,
      confirmButton,
      callback,
    }: {
      title: string;
      message: string;
      cancelButton: string;
      confirmButton: string;
      callback: (isConfirm: boolean) => void;
    }) {
      if (title) {
        this.title = title;
      }
      if (message) {
        this.message = message;
      }
      if (cancelButton) {
        this.cancelButton = cancelButton;
      }
      if (confirmButton) {
        this.confirmButton = confirmButton;
      }
      if (callback) {
        this.callback = callback;
      }
      this.modal?.show();
    },
    hide() {
      this.modal?.hide();
    },
  },
});
</script>
