import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1149e662"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  "aria-labelledby": "warningMessage",
  ref: "modal"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "d-flex justify-content-center py-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "modal-body text-center border-0" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "d-flex justify-content-evenly pt-2 pb-4" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            class: "modal-title fs-4 fw-bold",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", {
            class: "p-5",
            innerHTML: _ctx.message
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-avn-gray btn-sm rounded-pill p-3 w-25",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
            innerHTML: _ctx.cancelButton
          }, null, 8, _hoisted_9),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-avn-white btn-sm rounded-pill p-3 w-25",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args))),
            innerHTML: _ctx.confirmButton
          }, null, 8, _hoisted_10)
        ])
      ])
    ])
  ], 512))
}