import Store from '@/store';
import Router from '@/router';
import AuthenticationData from '@/types/AuthenticationData';
import { JWTRefreshError } from '@/types/Errors';
import { leftSessionTime } from '@/helpers/Helper';

export async function sendPincode(email: string) {
  const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/auth/code`, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch code');
  }

  return await response.json();
}

export async function newToken(email: string, code: string): Promise<AuthenticationData> {
  const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/auth/token`, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      code,
    }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch token');
  }

  return await response.json();
}

export async function refreshToken(refreshToken: string): Promise<AuthenticationData> {
  const timeRemaining = leftSessionTime();
  if (timeRemaining > (60 * 1000)) {
    // console.debug('Token extended: ', timeRemaining);
    const accessToken = Store.getters.accessToken;
    return {
      access: accessToken,
      refresh: refreshToken,
    };
  }

  // console.debug('Token refresh request: ', timeRemaining);
  const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/auth/refresh`, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${refreshToken}`,
    },
  });
  if (!response.ok) {
    throw new JWTRefreshError('Failed to refresh token');
  }

  return await response.json();
}

export async function fetchWithJWT(requestURL: string, options?: RequestInit): Promise<Response> {
  let tokenData: AuthenticationData;
  try {
    tokenData = await refreshToken(Store.getters.refreshToken);
    if (tokenData.access !== Store.getters.accessToken) {
      // console.debug('Token extended result(fetchWithJWT): ', tokenData);
      Store.commit('setAccessToken', tokenData.access ?? '');
      Store.commit('setRefreshToken', tokenData.refresh ?? '');
    }
  } catch (error) {
    // console.debug('Token refresh failed(fetchWithJWT): ', error);
    Store.commit('setAccessToken', '');
    Store.commit('setRefreshToken', '');
    Router.push({ name: 'login' });
    throw error;
  }

  if (options) {
    const headers = new Headers(options.headers ?? {});
    headers.set('Authorization', `Bearer ${tokenData.access}`);
    options.headers = headers;
  }
  else {
    options = {
      headers: {
        'Authorization': `Bearer ${tokenData.access}`,
      },
    };
  }

  // console.debug('Request with token: ', leftSessionTime(), requestURL);
  return await fetch(requestURL, options);
}

export default {
  sendPincode,
  newToken,
  refreshToken,
};
