import { createStore } from 'vuex';

export default createStore({
  state: {
    accessToken: '',
    refreshToken: '',
    contentsViewType: 'grid',
  },
  getters: {
    accessToken(state): string {
      return state.accessToken || localStorage.getItem('accessTokenString') || '';
    },
    refreshToken(state): string {
      return state.refreshToken || localStorage.getItem('refreshTokenString') || '';
    },
    contentsViewType(state): string {
      let viewType = localStorage.getItem('contentsViewType');
      if (!viewType) {
        viewType = 'grid';
        localStorage.setItem('contentsViewType', viewType);
      }
      state.contentsViewType = viewType;
      return state.contentsViewType;
    },
  },
  mutations: {
    setAccessToken(state, value) {
      localStorage.setItem('accessTokenString', value);
      return (state.accessToken = value);
    },
    setRefreshToken(state, value) {
      localStorage.setItem('refreshTokenString', value);
      return (state.refreshToken = value);
    },
    setContentsViewType(state, value) {
      localStorage.setItem('contentsViewType', value);
      return (state.contentsViewType = value);
    },
  },
  actions: {},
  modules: {},
});
