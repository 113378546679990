<template>
  <div class="position-relative" v-bind="$attrs">
    <template v-if="$attrs.disabled === undefined">
      <div @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHandler" ref="dropdown">
        <div
          :class="[
            'position-relative',
            'form-control',
            'border-avn-input-bg',
            'bg-avn-input-bg',
            'dropdown-text',
            'dropdown-hover',
            hideDropDown ? '' : 'rounded-bottom-0',
          ]"
          @click="toggleDropDown"
        >
          {{ dropDownTitleModel }}
          <i
            :class="['position-absolute', 'top-50', 'end-0', 'translate-middle-y', 'bi', hideDropDown ? 'bi-caret-down-fill' : 'bi-caret-up-fill', 'pe-2']"
          ></i>
        </div>
        <div
          :class="{
            'position-absolute': true,
            'w-100': true,
            'd-none': hideDropDown,
          }"
          :style="{
            'z-index': 10,
          }"
        >
          <div
            v-for="(option, index) in options"
            :key="index"
            :class="{
              'form-control': true,
              'rounded-0': true,
              'rounded-bottom-1': index == options.length - 1,
              'bg-avn-input-bg': true,
              'dropdown-text': true,
              'dropdown-hover': true,
              'dropdown-selected': isSelected(option),
              'text-truncate': true,
            }"
            @click="toggleSelect(option)"
          >
            {{ option.value }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <input :class="['form-control']" :disabled="true" :value="makeTitle(this.selectModel)" :placeholder="$attrs.placeholder as string ?? ``" />
    </template>
  </div>
</template>

<style scoped>
.dropdown-hover:hover {
  background-color: var(--bs-avn-gray) !important;
  color: var(--bs-avn-white) !important;
}

.dropdown-text {
  cursor: pointer;
  color: rgba(var(--bs-avn-input-color-rgb), 1);
  font-weight: 500 !important;
}

.dropdown-disabled {
  cursor: not-allowed;
  color: rgba(var(--bs-avn-input-color-rgb), 0.5);
  font-weight: 500 !important;
}

.dropdown-selected {
  background-color: var(--bs-avn-black) !important;
  color: var(--bs-avn-white) !important;
  font-weight: 500 !important;
}
</style>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import KeyValue from '@/types/KeyValue';

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<KeyValue[]>, required: true },
    options: { type: Array as PropType<KeyValue[]>, required: true },
    isMultiple: { type: Boolean, default: false },
  },
  data() {
    return {
      dropDownTitleModel: this.isMultiple ? 'Select all' : 'Select one',
      hideDropDown: true,
      hideDropDownTimeout: 0,
    };
  },
  methods: {
    isSelected(option: KeyValue) {
      if (this.modelValue === undefined || this.modelValue === null || this.modelValue.length === 0) {
        return false;
      }
      const keys = this.modelValue.map((item: KeyValue) => item.key);
      return keys.includes(option.key);
    },
    toggleDropDown() {
      this.hideDropDown = !this.hideDropDown;
      if (this.hideDropDown === false) {
        clearTimeout(this.hideDropDownTimeout);
      }
    },
    toggleSelect(option: KeyValue) {
      if (!this.isMultiple) {
        this.selectModel = [option];
        this.hideDropDown = true;
        this.dropDownTitleModel = option.value as string;
        return;
      }

      let selected: KeyValue[] = [];
      if (this.isSelected(option)) {
        selected = this.selectModel.filter((item: KeyValue) => item.key !== option.key);
      } else {
        selected = [...this.selectModel, option];
      }
      this.selectModel = selected;
      this.dropDownTitleModel = this.makeTitle(selected);
    },
    mouseLeaveHandler(event: MouseEvent) {
      if (event.target != this.$refs.dropdown) {
        return;
      }
      this.hideDropDownTimeout = setTimeout(() => {
        this.hideDropDown = true;
      }, 150);
    },
    mouseEnterHandler() {
      clearTimeout(this.hideDropDownTimeout);
    },
    makeTitle(model: KeyValue[]): string {
      if (model.length == 1) {
        return model[0].value as string;
      }
      if (model.length > 1) {
        return (model[0].value as string) + ' (+' + (model.length - 1) + ')';
      }
      return this.dropDownTitleModel;
    },
  },
  mounted() {
    if (this.modelValue) {
      this.dropDownTitleModel = this.makeTitle(this.modelValue);
    }
  },
  computed: {
    selectModel: {
      get(): KeyValue[] {
        return this.modelValue;
      },
      set(value: KeyValue[]) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    modelValue(value: KeyValue[]) {
      this.dropDownTitleModel = this.makeTitle(value);
    },
  },
});
</script>
