<template>
  {{ localeNumber }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    number: { type: [String, Number], required: true },
    options: { type: Object, default: () => ({}) },
  },
  computed: {
    localeNumber(): string {
      const number = Number(this.number);
      if (isNaN(number)) {
        return '-';
      }

      return number.toLocaleString('en-US', this.options);
    },
  },
});
</script>
