<template>
  <div class="modal fade" tabindex="-1" aria-labelledby="warningMessage" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="d-flex justify-content-center py-2">
          <span class="modal-title fs-4 fw-bold" v-html="title"></span>
        </div>
        <div class="modal-body text-center border-0">
          <p class="p-5" v-html="message" />
        </div>
        <div class="d-flex justify-content-evenly pt-2 pb-4">
          <button type="button" class="btn btn-avn-white btn-sm rounded-pill p-3 w-25" data-bs-dismiss="modal" v-html="button" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-content {
  z-index: 1000000 !important;
  background-color: #373737;
  color: #fff;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

export default defineComponent({
  data() {
    return {
      title: 'AVNANCE alert',
      message: 'MESSAGE',
      button: 'CONFIRM',
      modal: null as Modal | null,
    };
  },
  mounted() {
    const modalElement = this.$refs.modal as HTMLElement;
    if (modalElement) {
      this.modal = new Modal(modalElement, { backdrop: 'static' });
    }
  },
  unmounted() {
    this.modal?.hide();
  },
  methods: {
    show({ title, message, button, callback }: { title: string; message: string; button: string; callback: () => void }) {
      if (title) {
        this.title = title;
      }
      if (message) {
        this.message = message;
      }
      if (button) {
        this.button = button;
      }
      const modalElement = this.$refs.modal as HTMLElement;
      if (callback) {
        modalElement.addEventListener('hidden.bs.modal', callback, { once: true });
      }
      this.modal?.show();
    },
    hide() {
      this.title = 'TITLE';
      this.message = 'MESSAGE';
      this.button = 'Confirm';
      this.modal?.hide();
    },
  },
});
</script>
