import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyToClipboard && _ctx.copyToClipboard(...args)))
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}