import { v4 } from 'uuid';
import { jwtDecode } from "jwt-decode";
import JWTPayload from '@/types/JWTPayload';
import Store from '@/store';
import Router from '@/router';

export function newUUIDv4(): string {
  let uuid = v4().replaceAll('-', '');
  if (Number.isInteger(Number(uuid[0]))) {
    uuid = `a${uuid.slice(0, -1)}`;
  }
  return uuid;
}

export function createRandomString(length: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export function leftSessionTime(): number {
  const tokken = Store.getters.accessToken;
  if (!tokken) {
    return 0;
  }

  let timeRemaining = 0;
  try {
    const decodedToken = jwtDecode(tokken) as JWTPayload;
    const expireDatetime = new Date(decodedToken.exp * 1000);
    timeRemaining = expireDatetime.getTime() - Date.now();
  } catch (error) {
    console.error(error, tokken);
    Router.push({ name: 'login' });
  }

  return (timeRemaining > 0) ? timeRemaining : 0;
}

export function leftRefreshTime(): number {
  const tokken = Store.getters.refreshToken;
  if (!tokken) {
    return 0;
  }

  let timeRemaining = 0;
  try {
    const decodedToken = jwtDecode(tokken) as JWTPayload;
    const expireDatetime = new Date(decodedToken.exp * 1000);
    timeRemaining = expireDatetime.getTime() - Date.now();
  } catch (error) {
    console.error(error, tokken);
    Router.push({ name: 'login' });
  }

  return (timeRemaining > 0) ? timeRemaining : 0;
}

export function isAuthroized(): boolean {
  return (leftRefreshTime() > (10 * 1000));
}

export function sessionData(): JWTPayload | false {
  const tokken = Store.getters.accessToken;
  if (!tokken) {
    return false;
  }

  let result = false as JWTPayload | false;
  try {
    result = jwtDecode(tokken) as JWTPayload;
  } catch (error) {
    console.error(error, tokken);
  }
  return result;
}

export function getNumber(value: unknown): number | false {
  const num = Number(value);
  return isNaN(num) ? false : num;
}

export function getDate(value: unknown): Date | false {
  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'string') {
    return new Date(value);
  }

  return false;
}

export function toHTMLEscape(value: string): string {
  return value.replace(/[\u00A0-\u9999<>&]/g, (match: string) => {
    return '&#' + match.charCodeAt(0) + ';';
  });
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default {
  newUUIDv4,
  createRandomString,
  leftSessionTime,
  isAuthroized,
  sessionData,
  getNumber,
  getDate,
  toHTMLEscape,
  isValidEmail,
};
