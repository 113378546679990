<template>
  <button @click="copyToClipboard" v-bind="$attrs"><slot></slot></button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Clipboard from 'clipboard'

export default defineComponent({
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  emits: ['success', 'error'],
  methods: {
    copyToClipboard() {
      const modalContainer = this.$el.closest('.modal');
      const buttonElement = document.createElement('button');
      const clipboard = new Clipboard(buttonElement, {
        text: () => this.data,
        action: () => 'copy',
        container: modalContainer ? modalContainer : document.body,
      });
      clipboard.on('success', (event) => {
        clipboard.destroy();
        this.$emit('success', event);
      })
      clipboard.on('error', (error) => {
        clipboard.destroy();
        this.$emit('error', error);
      })
      buttonElement.click();
    },
  },
});
</script>
