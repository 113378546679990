import DropDown from '@/components/elements/DropDown.vue';
import NumberText from '@/components/elements/NumberText.vue';
import InputRadio from '@/components/elements/InputRadio.vue';
import InputCheckbox from '@/components/elements/InputCheckbox.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import DatePickerRange from '@/components/elements/DatePickerRange.vue';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';
import ClipboardCopyButton from '@/components/elements/ClipboardCopyButton.vue';

export default {
  DropDown,
  NumberText,
  InputRadio,
  InputCheckbox,
  DatePicker,
  DatePickerRange,
  LoadingSpinner,
  ClipboardCopyButton,
};
