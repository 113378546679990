import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatPickr = _resolveComponent("FlatPickr")!

  return (_ctx.$attrs.disabled)
    ? (_openBlock(), _createElementBlock("input", {
        key: 0,
        class: "form-control",
        value: _ctx.dateModel,
        placeholder: _ctx.$attrs.placeholder as string ?? `Select date`,
        disabled: ""
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_FlatPickr, {
        key: 1,
        class: "form-control",
        modelValue: _ctx.dateModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateModel) = $event)),
        config: _ctx.pickerConfig,
        placeholder: _ctx.$attrs.placeholder ?? `Select date`
      }, null, 8, ["modelValue", "config", "placeholder"]))
}