<template>
  <RouterView />
  <ModalWarningMessage ref="modalWarningMessage" />
  <ModalConfirmMessage ref="modalConfirmMessage" />
</template>

<style>
#app {
  font-family: 'Rajdhani', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.rotate-270 {
  transform: rotate(270deg) !important;
}

.modal {
  z-index: 10000000 !important;
  background-color: rgba(55, 55, 55, 0.7);
}

.form-control,
.form-check {
  font-weight: 500 !important;
}

.form-check-input[type='radio'] {
  background-color: var(--bs-avn-input-bg);
  border: max(2px, 0.12rem) solid var(--bs-avn-black);
  border-radius: 50%;
}

.form-check-input:focus[type='radio'],
.form-check-input:checked[type='radio'] {
  border-color: var(--bs-avn-black);
  background-color: var(--bs-avn-input-bg);
}

.form-check-input:checked[type='radio'] {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-2.5 -2.5 5 5%27%3e%3ccircle r=%272%27 fill=%27%23F12D77%27/%3e%3c/svg%3e);
}

.form-check-input[type='checkbox'] {
  background-color: var(--bs-avn-input-bg);
  border: max(2px, 0.12rem) solid var(--bs-avn-black);
  border-radius: 0.2rem;
}

.form-check-input:focus[type='checkbox'],
.form-check-input:checked[type='checkbox'] {
  border-color: var(--bs-avn-black);
  background-color: var(--bs-avn-input-bg);
}

.form-check-input:checked[type='checkbox'] {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-0.5 -0.5 5 5%27%3e%3crect width=%274%27 height=%274%27 rx=%270.4%27 fill=%27%23F12D77%27/%3e%3c/svg%3e);
}

.hide-decoration a {
  text-decoration: none;
}

.hide-decoration a:hover {
  text-decoration: underline;
}

.hover-avn-black:hover {
  background-color: var(--bs-avn-black) !important;
  color: var(--bs-avn-white) !important;
}

.hover-avn-pink:hover {
  background-color: var(--bs-avn-pink) !important;
  color: var(--bs-avn-white) !important;
}

input:disabled {
  cursor: default;
  background-color: var(--bs-avn-input-disabled-bg) !important;
  color: var(--bs-avn-input-disabled-color) !important;
}

thead {
  border-top: 2px solid var(--bs-avn-black) !important;
}

th {
  font-weight: 500 !important;
}

.table-headborderless {
  border-collapse: separate;
  border-spacing: 0;
}

.translate-outer {
  transform: translate(-100%, -100%);
}

.translate-outer-x {
  transform: translateX(-100%);
}

.translate-outer-y {
  transform: translateY(-100%);
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import ModalConfirmMessage from '@/components/modals/ConfirmMessage.vue';
import ModalWarningMessage from '@/components/modals/WarningMessage.vue';

export default defineComponent({
  components: {
    ModalConfirmMessage,
    ModalWarningMessage,
  },
  methods: {
    showWarningModal(options: Record<string, unknown>) {
      (this.$refs.modalWarningMessage as typeof ModalConfirmMessage).show(options);
    },
    hideWarningModal() {
      (this.$refs.modalWarningMessage as typeof ModalConfirmMessage).hide();
    },
    showConfirmModal(options: Record<string, unknown>) {
      (this.$refs.modalConfirmMessage as typeof ModalConfirmMessage).show(options);
    },
    hideConfirmModal() {
      (this.$refs.modalConfirmMessage as typeof ModalConfirmMessage).hide();
    },
  },
});
</script>
